<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('users.lookingFor') }}</span>
				</v-card-title>
				<v-card-text>
					<UserInterestsLooking :loading-parent="loadingParent" />
					<UserInterestsSectors :loading-parent="loadingParent" />
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
export default {
	name: 'UserInterests',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	components: {
		UserInterestsLooking: () => import('@/components/users/UserInterestsLooking.vue'),
		UserInterestsSectors: () => import('@/components/users/UserInterestsSectors.vue')
	}
}
</script>
